<template lang="pug">
.analytics-table-multi-source(:class="tabClass")
  .analytics-tabs.d-flex
    om-heading(h3) {{ $t('analyticsPage.campaignPerformance') }}
    .goal-tabs(ref="goal-tabs-container")
      om-pills(:pills="tabs" :selected="selectedTab" @select="selectTab")
    .last-updated.d-flex.pl-2 {{ lastUpdated }}

  .domain-content.d-flex(:class="{ 'scrollable-to-the-right': !scrollInTopMostRightPosition }")
    .table.brand-table.col-5.col-xl-3.col.px-0.analytics-table-column-main(
      :class="{ 'scrollable-to-the-left': !scrollInTopMostLeftPosition }"
    )
      .thead.mb-0.p-3
        .tr
          .th(scope="col" style="visibility: hidden")
            span
              span.table-column-header.d-flex.align-items-center
                om-tooltip(:delay="300")
                  div
                  template(slot="trigger")
                    span.title {{ 'Campaign name' }}

      .tbody(:ref="'scrollTable'")
        .tr.brand-table-tr.campaign-header(
          v-for="(campaign, campaignIndex) in multiSourceAnalytics"
          :class="{ 'campaign-is-deleted': campaign.status === 'deleted', 'brand-table-tr-no-variant-column': campaign.variants.length === 1 }"
        )
          .td.brand-table-td(
            :class="{ 'brand-table-td-variant-column': campaign.variants.length > 1 }"
          )
            .d-flex.table.brand-table.align-items-center(
              @click="toggleClosed({ campaignId: campaign.id })"
              :class="{ 'brand-table-td-variant-column-header cursor-pointer': campaign.variants.length > 1, hovered: isHover({ campaignId: campaign.id }) }"
              :style="[getRowHeight(campaign.id) && { height: getRowHeight(campaign.id) + 'px' }]"
              @mouseenter="setHover({ campaignId: campaign.id })"
              @mouseleave="removeHover({ campaignId: campaign.id })"
            )
              .brand-table-tr-inner(v-if="campaign.variants.length > 1")
                .brand-table-td-inner.d-flex.align-items-center.position-relative.pl-2.multi-variant-td
                  .d-flex.font-weight-500.line-clamp.align-items-center
                    span.campaign-report-campaign-name {{ getCampaignName(campaign, columnsForTab[0].key) }}
                  span.toggle-arrow
                    UilAngleUp(
                      v-if="isToggled({ campaignId: campaign.id })"
                      width="1.5em"
                      height="1.5em"
                    )
                    UilAngleDown(v-else width="1.5em" height="1.5em")
                  a.hidden.campaign-settings-link.ml-2(
                    :href="getCampaignSettingsLink(campaign.id)"
                    target="_blank"
                    v-if="campaign.status !== 'deleted'"
                  )
                    UilExternalLinkAlt.hidden

              om-tooltip.variant-preview(transition="fade" :delay="300" v-else)
                om-progressive-image.template-preview.template-preview-small.flex-shrink-0(
                  :src="getPreviewUrl({ campaign })"
                  placeholder="/brand-skeleton.png"
                )
                template(slot="trigger")
                  .brand-table-tr-inner
                    .brand-table-td-inner.d-flex.align-items-center.position-relative.pr-3.pl-2
                      .d-flex.font-weight-500.line-clamp.align-items-center
                        span.campaign-report-campaign-name(
                          :title="getRowValue(campaign, columnsForTab[0].key)"
                        ) {{ getCampaignName(campaign, columnsForTab[0].key) }}
                      a.hidden.campaign-settings-link.ml-2(
                        :href="getCampaignSettingsLink(campaign.id)"
                        target="_blank"
                        v-if="campaign.status !== 'deleted'"
                      )
                        UilExternalLinkAlt.hidden

            .table.brand-table.brand-table-variants(
              v-if="campaign.variants.length > 1"
              :class="{ 'closed-up': !isToggled({ campaignId: campaign.id }) }"
            )
              .variant-preview(
                transition="fade"
                v-for="variant in campaign.variants"
                :key="variant.id"
              )
                .tr.brand-table-tr.brand-table-tr-variant(
                  :class="{ 'variant-is-deleted': variant.status === 'deleted', hovered: isHover({ campaignId: campaign.id, variantId: variant.id }) }"
                  @mouseenter="setHover({ campaignId: campaign.id, variantId: variant.id })"
                  @mouseleave="removeHover({ campaignId: campaign.id, variantId: variant.id })"
                  :style="[getRowHeight(campaign.id, variant.id) >= 55 && { height: getRowHeight(campaign.id, variant.id) + 'px' }]"
                )
                  .td.brand-table-td.brand-table-td-variant.d-flex.align-items-center
                    span.ml-2.mr-1
                      angle-down.gray-400
                    om-tooltip(:delay="300")
                      om-progressive-image.template-preview.template-preview-small.flex-shrink-0(
                        :src="getPreviewUrl({ variant })"
                        placeholder="/brand-skeleton.png"
                      )
                      template(slot="trigger")
                        span.line-clamp.variant-name
                          span {{ getRowValue(variant, columnsForTab[0].key) }}
                          span.mx-1.deleted-text(v-if="variant.status === 'deleted'") ({{ $t('analyticsPage.deleted') }})
                        span.line-clamp.variant-name.ml-1(v-if="variant.experience")
                          span {{ variant.experience.name }}
                          span.mx-1.deleted-text(v-if="variant.status === 'deleted'") ({{ $t('analyticsPage.deleted') }})

    .table.brand-table.overflow-y-auto.col.px-0.analytics-table-column-secondary(
      :ref="'scrollBody'"
      @scroll="getScroll()"
    )
      .thead.scroll.mb-0.p-3.px-0
        .tr.justify-content-between
          .th.pl-2(
            scope="col"
            v-for="(column, columnIndex) in columnsForTab.slice(1)"
            :class="classes(column.key)"
          )
            span
              span.table-column-header.d-flex.align-items-center
                om-tooltip(:placement="'top'" :delay="300")
                  div(v-html="$t(`analyticsPage.header-explanation-${column.key}`)")
                  template(slot="trigger")
                    span.title {{ column.header }}

      .tbody.scroll
        .tr.brand-table-tr(
          v-for="campaign in multiSourceAnalytics"
          :class="{ hovered: isHover({ campaignId: campaign.id }) }"
        )
          .td.brand-table-td(:class="{ 'brand-table-parent-row': campaign.variants.length > 1 }")
            .table.brand-table-parent
              .tr.brand-table-tr-inner.d-flex.justify-content-between.table-end(
                :ref="`campaign-row-${campaign.id}`"
                @mouseenter="setHover({ campaignId: campaign.id })"
                @mouseleave="removeHover({ campaignId: campaign.id })"
              )
                .td.brand-table-td-inner.flex-wrap.pl-2(
                  v-for="column in columnsForTab.slice(1)"
                  :class="classes(column.key)"
                )
                  .table-row-value(v-if="simpleValues.includes(column.key)")
                    campaign-report-value(
                      :value="getRowValue(campaign, column.key)"
                      :columnKey="column.key"
                      :isLoading="loadingValue(column.key)"
                      :isError="errorValue(column.key)"
                    )
                  .table-row-value(
                    v-else-if="column.key === 'assistedOrders' || column.key === 'totalOrders'"
                  )
                    campaign-report-value(
                      :value="sumOrderNumber(getRowValue(campaign, column.key))"
                      :columnKey="column.key"
                      :isLoading="loadingValue(column.key)"
                      :isError="errorValue(column.key)"
                    )
                  .table-row-value(v-else)
                    template(v-if="getRowValue(campaign, column.key) === 'N/A'") N/A
                    template(v-else-if="isNullValue(getRowValue(campaign, column.key))") 0
                    template(
                      v-else
                      v-for="(value, currency, index) in getAdditionalCurrencies(getRowValue(campaign, column.key), column.key)"
                    )
                      campaign-report-value(
                        :value="value"
                        :columnKey="column.key"
                        :currency="currency"
                        :secondary="index !== 0"
                        :isLoading="loadingValue(column.key)"
                        :isError="errorValue(column.key)"
                      )

            .table.brand-table.brand-table-child.brand-table-variants(
              v-if="campaign.variants.length > 1"
              :class="{ 'closed-up': !isToggled({ campaignId: campaign.id }) }"
            )
              .tr.brand-table-tr.brand-table-tr-variant.justify-content-between.table-end(
                v-for="variant in campaign.variants"
                :ref="`campaign-variant-row-${campaign.id}-${variant.id}`"
                :class="{ hovered: isHover({ campaignId: campaign.id, variantId: variant.id }) }"
                @mouseenter="setHover({ campaignId: campaign.id, variantId: variant.id })"
                @mouseleave="removeHover({ campaignId: campaign.id, variantId: variant.id })"
              )
                .td.td-variant.d-flex.flex-column.justify-content-center.align-items-end.flex-wrap(
                  v-for="variantColumn in columnsForTab.slice(1)"
                  :class="classes(variantColumn.key)"
                )
                  .td.brand-table-td.brand-table-td-variant(
                    v-if="simpleValues.includes(variantColumn.key)"
                  )
                    campaign-report-value(
                      :value="getRowValue(variant, variantColumn.key)"
                      :columnKey="variantColumn.key"
                      :isLoading="loadingValue(variantColumn.key)"
                      :isError="errorValue(variantColumn.key)"
                    )
                  .td.brand-table-td.brand-table-td-variant(
                    v-else-if="variantColumn.key === 'assistedOrders' || variantColumn.key === 'totalOrders'"
                  )
                    campaign-report-value(
                      :value="sumOrderNumber(getRowValue(variant, variantColumn.key))"
                      :columnKey="variantColumn.key"
                      :isLoading="loadingValue(variantColumn.key)"
                      :isError="errorValue(variantColumn.key)"
                    )
                  .td.brand-table-td.brand-table-td-variant(v-else)
                    template(v-if="loadingValue(variantColumn.key)")
                      vue-skeleton-loader(type="rect" width="50%" height="24px" :rounded="true")
                    template(v-else-if="errorValue(variantColumn.key)")
                      vue-skeleton-loader(type="rect" width="50%" height="24px" :rounded="true")
                    template(v-else)
                      template(v-if="typeof getRowValue(variant, variantColumn.key) === 'string'")
                        campaign-report-value(
                          :value="getRowValue(variant, variantColumn.key)"
                          :columnKey="variantColumn.key"
                        )
                      template(v-else-if="isNullValue(getRowValue(variant, variantColumn.key))") 0
                      template(
                        v-else
                        v-for="(value, currency, index) in getAdditionalCurrencies(getRowValue(variant, variantColumn.key), variantColumn.key)"
                      )
                        campaign-report-value(
                          :value="value"
                          :columnKey="variantColumn.key"
                          :currency="currency"
                          :secondary="index !== 0"
                        )
</template>

<script>
  import { mapGetters } from 'vuex';
  import runTimeConfig from '@/config/runtime';
  import { get as _get } from 'lodash-es';
  import {
    UilAngleUp,
    UilAngleDown,
    UilExternalLinkAlt,
    UilInfoCircle,
  } from '@iconscout/vue-unicons';
  import AngleDown from '@/assets/admin/svg/UAngleDown.vue';
  import campaignReports from '@/mixins/campaignReports';
  import CampaignReportValue from '@/components/CampaignReportValue.vue';

  export default {
    components: {
      UilAngleUp,
      UilAngleDown,
      UilExternalLinkAlt,
      UilInfoCircle,
      AngleDown,
      CampaignReportValue,
    },
    mixins: [campaignReports],
    props: {
      analytics: { type: Array, required: true },
      chanceToWin: { type: Object, required: true },
      goalConversions: { type: Object, required: true },
      tabs: { type: Array, required: true },
      deviceType: { type: String },
      loadingGoal: { type: Boolean, required: true },
      goalError: { type: Boolean, required: true },
      loadingChanceToWin: { type: Boolean, required: true },
      chanceToWinError: { type: Boolean, required: true },
      selectedTab: { type: String, required: true },
      noDeviceData: { type: Boolean, default: false },
      lastUpdated: { type: String, required: true },
    },
    data() {
      return {
        hasTooManyGoals: false,
        hovered: [],
        closed: [],
        rowHeights: {},
        columns: {
          campaignConversion: [
            { header: this.$t('analyticsPage.campaignName'), key: 'name' },
            { header: this.$t('analyticsPage.visitors'), key: 'visitors' },
            { header: this.$t('analyticsPage.impressions'), key: 'impressions' },
            { header: this.$t('analyticsPage.conversions'), key: 'conversions' },
            { header: this.$t('analyticsPage.conversionRateHeader'), key: 'conversionRate' },
            { header: this.$t('analyticsPage.uplift'), key: 'uplift' },
            { header: this.$t('analyticsPage.chanceToWin'), key: 'chanceToWin' },
            { header: this.$t('analyticsPage.assistedOrders'), key: 'assistedOrders' },
            { header: this.$t('analyticsPage.assistedRevenue'), key: 'assistedRevenue' },
          ],
          orderEventGoal: [
            { header: this.$t('analyticsPage.campaignName'), key: 'name' },
            { header: this.$t('analyticsPage.visitors'), key: 'visitors' },
            { header: this.$t('analyticsPage.impressions'), key: 'impressions' },
            { header: this.$t('analyticsPage.conversionRateHeader'), key: 'conversionRate' },
            { header: this.$t('analyticsPage.uplift'), key: 'uplift' },
            { header: this.$t('analyticsPage.chanceToWin'), key: 'chanceToWin' },
            { header: this.$t('analyticsPage.totalOrders'), key: 'totalOrders' },
            { header: this.$t('analyticsPage.revenue'), key: 'revenue' },
            { header: this.$t('analyticsPage.aovHeader'), key: 'aov' },
            { header: this.$t('analyticsPage.revenueUplift'), key: 'revenueUplift' },
          ],
          otherGoals: [
            { header: this.$t('analyticsPage.campaignName'), key: 'name' },
            { header: this.$t('analyticsPage.visitors'), key: 'visitors' },
            { header: this.$t('analyticsPage.impressions'), key: 'impressions' },
            { header: this.$t('analyticsPage.conversionRateHeader'), key: 'conversionRate' },
            { header: this.$t('analyticsPage.uplift'), key: 'uplift' },
            { header: this.$t('analyticsPage.chanceToWin'), key: 'chanceToWin' },
          ],
        },
        goalColumns: ['conversionRate', 'goalConversion', 'uplift', 'chanceToWin'],
        scrollInTopMostLeftPosition: true,
        scrollInTopMostRightPosition: false,
        scrollPosition: 0,
      };
    },
    computed: {
      ...mapGetters(['isShoprenterDomain', 'isShopifyDomain']),
      columnsForTab() {
        if (this.selectedTab === 'campaignConversion') {
          return this.columns.campaignConversion;
        }
        const selectedGoal = this.tabs.find((goal) => goal.key === this.selectedTab);
        if (selectedGoal?.isOrderGoal) {
          return [
            ...this.columns.orderEventGoal.slice(0, 3),
            {
              header: selectedGoal.text,
              key: 'goalConversion',
            },
            ...this.columns.orderEventGoal.slice(3),
          ];
        }
        return [
          ...this.columns.otherGoals.slice(0, 3),
          {
            header: selectedGoal.text,
            key: 'goalConversion',
          },
          ...this.columns.otherGoals.slice(3),
        ];
      },
      campaignAnalytics() {
        return this.analytics
          .map((campaignAnalytics) => {
            const mappedCampaignData = this.mapCampaignConversion({ row: campaignAnalytics });

            const baseLine = this.getBaseLine(campaignAnalytics);

            return {
              ...mappedCampaignData,
              variants: campaignAnalytics.variants
                .map((variant) => {
                  return {
                    ...this.mapCampaignConversion({
                      row: variant,
                      campaignId: campaignAnalytics.id,
                      baseLineCR: baseLine.conversionRate,
                      isBaseLine: baseLine.id === variant.id,
                    }),
                  };
                })
                .sort(this.compareVariants),
            };
          })
          .sort(this.compareCampaigns);
      },
      goalData() {
        return this.analytics
          .map((campaignAnalytics) => {
            const mappedCampaignData = this.mapGoalData({ row: campaignAnalytics });

            const baseLine = this.getBaseLine(campaignAnalytics);
            const baseLineGoalCount =
              this.goalConversions?.[this.selectedTab]?.[campaignAnalytics.id]?.variants?.[
                baseLine.id
              ]?.goalCount[this.deviceType];
            const baseLineCR = baseLineGoalCount
              ? baseLineGoalCount / baseLine.visitorCount[this.deviceType]
              : null;
            const baseLineRevenue = baseLine.assistedRevenue.fiveDaysShown[this.deviceType] ?? null;
            const baseLineVisitorCount = baseLine.visitorCount[this.deviceType] ?? null;

            return {
              ...mappedCampaignData,
              variants: campaignAnalytics.variants
                .map((variant) => {
                  return {
                    ...this.mapGoalData({
                      row: variant,
                      campaignId: campaignAnalytics.id,
                      isBaseLine: baseLine.id === variant.id,
                      baseLineCR,
                      baseLineRevenue,
                      baseLineVisitorCount,
                    }),
                  };
                })
                .sort(this.compareVariants),
            };
          })
          .sort(this.compareCampaigns);
      },
      isOrderGoalSelected() {
        const selectedGoal = this.tabs.find((tab) => tab.key === this.selectedTab);
        return selectedGoal?.isOrderGoal;
      },
      multiSourceAnalytics() {
        if (this.selectedTab === 'campaignConversion') {
          return this.campaignAnalytics;
        }
        return this.goalData;
      },
      tabClass() {
        const isHun = this.$i18n.locale === 'hu';
        let selectedTabClass = '';
        if (this.selectedTab === 'campaignConversion') {
          selectedTabClass = 'campaign-conversion';
        } else if (this.isOrderGoalSelected) {
          selectedTabClass = 'order-goal';
        } else {
          selectedTabClass = 'goal';
        }
        return `${isHun ? 'analytics-table-multi-source-hu ' : ''}${selectedTabClass}`;
      },
    },
    watch: {
      selectedTab() {
        this.$nextTick(() => {
          this.calculateRowHeights();
          this.getScroll();
        });
      },
      deviceType() {
        this.$nextTick(() => {
          this.calculateRowHeights();
        });
      },
    },
    mounted() {
      window.addEventListener('resize', this.calculateRowHeights);
      this.calculateRowHeights();
      this.getScroll();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.calculateRowHeights);
    },
    methods: {
      classes(key) {
        return {
          'column-impressions': key === 'impressions',
          'column-visitors': key === 'visitors',
          'column-conversions': key === 'conversions',
          'column-conversion-rate': key === 'conversionRate',
          'column-uplift': key === 'uplift',
          'column-chance-to-win': key === 'chanceToWin',
          'column-assisted-orders': key === 'assistedOrders',
          'column-total-orders': key === 'totalOrders',
          'column-assisted-revenue': key === 'assistedRevenue',
          'column-revenue': key === 'revenue',
          'column-aov': key === 'aov',
          'column-revenue-uplift': key === 'revenueUplift',
          'column-goal-conversion': key === 'goalConversion',
        };
      },
      setHover({ campaignId, variantId }) {
        const inHover = this.hovered.some(
          (c) => c.campaignId === campaignId && c.variantId === variantId,
        );

        if (!inHover) {
          this.hovered.push({ campaignId, variantId });
        }
      },
      removeHover({ campaignId, variantId }) {
        const index = this.hovered.findIndex(
          (c) => c.campaignId === campaignId && c.variantId === variantId,
        );

        if (index > -1) {
          this.hovered.splice(index, 1);
        }
      },
      getPreviewUrl({ campaign, variant }) {
        let id = variant?.id;
        if (campaign) {
          const variants = campaign.variants.filter((v) => !v.isControlVariant);
          id = variants[0]?.id;
        } else if (variant.isControlVariant) {
          return require('@/assets/controlVariantPreview.svg');
        }
        const userId = this.$route.params.userId;
        return `${runTimeConfig.VUE_APP_CDN_URL}/public/${userId}/${id}/page1.png`;
      },
      getRowValue(row, path) {
        return _get(row, path);
      },
      getCampaignSettingsLink(campaignId) {
        const userId = this.$route.params.userId;
        return `/${userId}/campaign/${campaignId}`;
      },
      isToggled(row) {
        return this.closed.some((c) => c.campaignId === row.campaignId);
      },
      toggleClosed(row) {
        const index = this.closed.findIndex((c) => c.campaignId === row.campaignId);
        if (index > -1) {
          this.closed.splice(index, 1);
        } else {
          this.closed.push(row);
        }
        this.$nextTick(() => {
          this.calculateRowHeights();
        });
      },
      isHover({ campaignId, variantId }) {
        let index = -1;
        if (variantId) {
          index = this.hovered.findIndex(
            (c) => c.campaignId === campaignId && c.variantId === variantId,
          );
        } else {
          index = this.hovered.findIndex((c) => c.campaignId === campaignId && !c.variantId);
        }

        if (index > -1) return true;
      },
      getBaseLine(campaign) {
        const controlVariant = campaign.variants.find((variant) => variant.isControlVariant);
        if (controlVariant) return controlVariant;

        return campaign.variants.sort(
          (variantA, variantB) => new Date(variantA.createdAt) - new Date(variantB.createdAt),
        )[0];
      },
      mapCampaignConversion({ row, campaignId, baseLineCR, isBaseLine }) {
        const {
          visitorCount,
          assistedRevenue,
          conversionCount,
          conversionRate,
          impressionCount,
          orders,
          id,
          name,
          status,
          isControlVariant,
          experience,
          createdAt,
        } = row;

        let chanceToWin;
        let uplift;
        if (campaignId) {
          chanceToWin =
            this.chanceToWin[this.selectedTab]?.[campaignId]?.[this.deviceType]?.[row.id];

          uplift = baseLineCR[this.deviceType]
            ? (conversionRate[this.deviceType] - baseLineCR[this.deviceType]) /
              baseLineCR[this.deviceType]
            : 'N/A';
        }

        return {
          visitors: visitorCount[this.deviceType] ?? 0,
          impressions: impressionCount[this.deviceType] ?? 0,
          conversions: conversionCount[this.deviceType] ?? 0,
          conversionRate: conversionRate[this.deviceType] ?? 0,
          uplift: isBaseLine ? this.$t('analyticsPage.baseline') : uplift,
          chanceToWin: chanceToWin ?? 'N/A',
          assistedOrders: orders.fiveDaysFilled[this.deviceType] ?? 0,
          assistedRevenue: assistedRevenue.fiveDaysFilled[this.deviceType] ?? 0,
          id,
          name,
          status,
          createdAt,
          ...(campaignId && { isControlVariant, experience }),
        };
      },
      calculateAOV({ revenue, totalOrders }) {
        return Object.entries(revenue).reduce((aov, [currency, value]) => {
          aov[currency] = totalOrders[currency] ? (value || 0) / totalOrders[currency] : 'N/A';
          return aov;
        }, {});
      },
      calculateRevenueUplift({ visitorCount, baseLineVisitorCount, revenue, baseLineRevenue }) {
        if (!baseLineRevenue) return 'N/A';
        if (!revenue) return 0;
        return Object.entries(revenue).reduce((revenueUplift, [currency, value]) => {
          const baseLineRevenuePerVisitor = baseLineVisitorCount
            ? baseLineRevenue[currency] / baseLineVisitorCount
            : 0;
          const variantRevenuePerVisitor = value ? value / visitorCount : 0;

          revenueUplift[currency] = baseLineRevenuePerVisitor
            ? (variantRevenuePerVisitor - baseLineRevenuePerVisitor) / baseLineRevenuePerVisitor
            : 'N/A';
          return revenueUplift;
        }, {});
      },
      getCalculatedValuesForGoals({ campaignId, row, visitors, isBaseLine, baseLineCR }) {
        let goalConversion;
        let uplift;
        let chanceToWin;
        let conversionRate;

        if (!this.noDeviceData) {
          if (campaignId) {
            goalConversion =
              this.goalConversions?.[this.selectedTab]?.[campaignId]?.variants?.[row.id]
                ?.goalCount?.[this.deviceType] || 0;

            chanceToWin =
              this.chanceToWin[this.selectedTab]?.[campaignId]?.[this.deviceType]?.[row.id];
          } else {
            goalConversion =
              this.goalConversions?.[this.selectedTab]?.[row.id]?.goalCount?.[this.deviceType] || 0;
          }
          conversionRate = visitors ? goalConversion / visitors : 'N/A';

          if (campaignId) {
            uplift = isBaseLine
              ? this.$t('analyticsPage.baseline')
              : baseLineCR && conversionRate !== 'N/A'
              ? (conversionRate - baseLineCR) / baseLineCR
              : 'N/A';
          }
        } else {
          goalConversion = 'N/A';
          uplift = 'N/A';
          chanceToWin = 'N/A';
          conversionRate = 'N/A';
        }

        return { goalConversion, uplift, chanceToWin, conversionRate };
      },
      mapGoalData({
        row,
        campaignId,
        baseLineCR,
        baseLineRevenue,
        baseLineVisitorCount,
        isBaseLine,
      }) {
        const {
          visitorCount,
          assistedRevenue,
          impressionCount,
          orders,
          id,
          name,
          status,
          createdAt,
          isControlVariant,
        } = row;
        const totalOrders = orders.fiveDaysShown[this.deviceType] ?? 0;
        const revenue = assistedRevenue.fiveDaysShown[this.deviceType] ?? 0;

        const visitors = visitorCount[this.deviceType] ?? 0;

        const { goalConversion, uplift, chanceToWin, conversionRate } =
          this.getCalculatedValuesForGoals({
            campaignId,
            row,
            visitors,
            isBaseLine,
            baseLineCR,
          });

        return {
          visitors,
          impressions: impressionCount[this.deviceType] ?? 0,
          goalConversion,
          conversionRate,
          uplift,
          chanceToWin: chanceToWin ?? 'N/A',
          id,
          name,
          status,
          createdAt,
          isControlVariant,
          ...(this.isOrderGoalSelected && {
            totalOrders,
            revenue,
            aov: this.calculateAOV({ revenue, totalOrders }),
            revenueUplift: isBaseLine
              ? this.$t('analyticsPage.baseline')
              : campaignId &&
                this.calculateRevenueUplift({
                  visitorCount: visitors,
                  baseLineVisitorCount,
                  revenue,
                  baseLineRevenue,
                }),
          }),
        };
      },
      calculateRowHeights() {
        this.analytics.forEach((campaign) => {
          const campaignRef = `campaign-row-${campaign.id}`;
          const headerHeight = this.$refs?.[campaignRef]?.[0]?.clientHeight;
          this.rowHeights[campaignRef] = headerHeight;
          campaign.variants.forEach((variant) => {
            const variantRef = `campaign-variant-row-${campaign.id}-${variant.id}`;
            const variantHeaderHight = this.$refs?.[variantRef]?.[0]?.clientHeight;
            this.rowHeights[variantRef] = variantHeaderHight;
          });
        });
        this.$forceUpdate();
      },
      getRowHeight(campaignId, variantId) {
        if (variantId) {
          return this.rowHeights[`campaign-variant-row-${campaignId}-${variantId}`];
        }
        return this.rowHeights[`campaign-row-${campaignId}`];
      },
      selectTab($event) {
        this.$emit('selectTab', {
          tab: $event,
        });
      },
      loadingValue(key) {
        if (this.loadingGoal && this.goalColumns.includes(key)) {
          return true;
        }
        if (this.loadingChanceToWin && key === 'chanceToWin') {
          return true;
        }
        return false;
      },
      errorValue(key) {
        if (this.goalError && this.goalColumns.includes(key)) {
          return true;
        }
        if (this.chanceToWinError && key === 'chanceToWin') {
          return true;
        }
        return false;
      },
      compareVariants(variant1, variant2) {
        if (variant1.isControlVariant) return -1;
        if (new Date(variant1.createdAt) < new Date(variant2?.createdAt)) {
          return -1;
        }
        if (new Date(variant1.createdAt) > new Date(variant2?.createdAt)) {
          return 1;
        }
        return 0;
      },
      compareCampaigns(campaign1, campaign2) {
        if (new Date(campaign1.createdAt) < new Date(campaign2.createdAt)) return 1;
        return -1;
      },
      getScroll() {
        const scrollPosition = this.$refs.scrollBody.scrollLeft;
        const offsetWidth = this.$refs.scrollBody.offsetWidth;
        const scrollWidth = this.$refs.scrollBody.scrollWidth;

        if (scrollPosition === 0 && offsetWidth + scrollPosition >= scrollWidth - 1) {
          this.scrollInTopMostLeftPosition = true;
          this.scrollInTopMostRightPosition = true;
        } else if (scrollPosition === 0) {
          this.scrollInTopMostLeftPosition = true;
          this.scrollInTopMostRightPosition = false;
        } else if (offsetWidth + scrollPosition >= scrollWidth - 1) {
          this.scrollInTopMostRightPosition = true;
          this.scrollInTopMostLeftPosition = false;
        } else {
          this.scrollInTopMostLeftPosition = false;
          this.scrollInTopMostRightPosition = false;
        }
      },
      getCampaignName(campaign, key) {
        const campaignName = this.getRowValue(campaign, key);

        let modifier = '';

        if (campaign.status === 'archived') {
          modifier = `(${this.$t('archived')}) `;
        } else if (campaign.status === 'deleted') {
          modifier = `(${this.$t('analyticsPage.deleted')}) `;
        }

        return `${modifier}${campaignName}`;
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '../sass/variables/_colors'

  .line-clamp
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box !important
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    text-align: left
    position: relative

  .toggle-arrow
    position: absolute
    right: 25px
    .ui-svg-inline
      vertical-align: -5px

  .multi-variant-td
    padding-right: 50px

  .analytics-table-multi-source
    border: 1px solid $om-gray-400
    border-radius: 4px
    padding: 2rem
    max-width: calc(100vw - 6rem - 36px)

    &.analytics-table-multi-source-hu
      &.campaign-conversion
        .domain-content
          &.scrollable-to-the-right
            @media screen and (max-width: 1700px)
              position: relative
              &:after
                content: ""
                position: absolute
                bottom: 0
                right: 0
                height: calc(100% - 200px)
                width: 10px
                background: -webkit-gradient(linear,left top,right top,from(transparent),to(rgba(0,0,0,.04)))
                background: linear-gradient(90deg, transparent, rgba(0,0,0,.04))
                z-index: 99999

          .analytics-table-column
            &-main
              &.scrollable-to-the-left
                @media screen and (max-width: 1700px)
                  &:before
                    content: ""
                    position: absolute
                    bottom: 0
                    right: -10px
                    height: calc(100% - 200px)
                    width: 10px
                    background: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.04)),to(transparent))
                    background: linear-gradient(90deg,rgba(0,0,0,.04) 0,transparent)
                    z-index: 99999

            &-secondary
              .thead.scroll,
              .tbody.scroll
                @media screen and (max-width: 1700px)
                  min-width: 1300px
              @media screen and (max-width: 1920px)
                .column-conversion-rate,
                .column-uplift
                  max-width: 120px

                .column-chance-to-win
                  max-width: 120px

                .column-assisted-orders,
                .column-assisted-revenue
                  min-width: 150px

              .column-assisted-orders,
              .column-assisted-revenue
                  min-width: 160px

      &.order-goal
        .domain-content
          &.scrollable-to-the-right
            @media screen and (max-width: 2400px)
              position: relative
              &:after
                content: ""
                position: absolute
                bottom: 0
                right: 0
                height: calc(100% - 200px)
                width: 10px
                background: -webkit-gradient(linear,left top,right top,from(transparent),to(rgba(0,0,0,.04)))
                background: linear-gradient(90deg, transparent, rgba(0,0,0,.04))
                z-index: 99999

          .analytics-table-column
            &-main
              &.scrollable-to-the-left
                @media screen and (max-width: 2400px)
                  &:before
                    content: ""
                    position: absolute
                    bottom: 0
                    right: -10px
                    height: calc(100% - 200px)
                    width: 10px
                    background: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.04)),to(transparent))
                    background: linear-gradient(90deg,rgba(0,0,0,.04) 0,transparent)
                    z-index: 99999

            &-secondary
              .thead.scroll,
              .tbody.scroll
                .column-goal-conversion
                  max-width: 180px
                @media screen and (max-width: 2400px)
                  min-width: 1200px
                  .column-goal-conversion
                    max-width: 180px

                  .column-conversion-rate,
                  .column-uplift,
                  .column-chance-to-win
                    max-width: 100px
                  .column-total-orders
                    max-width: 120px
                  .column-revenue,
                  .column-aov
                    max-width: 140px

      &.goal
        .domain-content
          .analytics-table-column
            &-main
              &.scrollable-to-the-left
                @media screen and (max-width: 1920px)
                  &:before
                    content: ""
                    position: absolute
                    bottom: 0
                    right: -10px
                    height: calc(100% - 200px)
                    width: 10px
                    background: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.04)),to(transparent))
                    background: linear-gradient(90deg,rgba(0,0,0,.04) 0,transparent)
                    z-index: 99999
            &-secondary
              .thead.scroll,
              .tbody.scroll
                .column-uplift,
                .column-conversion-rate
                  max-width: 130px
                .column-chance-to-win
                  max-width: 180px
                .column-visitors,
                .column-impressions
                  max-width: 250px
                .column-goal-conversion
                  max-width: 126px
                @media screen and (max-width: 1919px)
                  .column-visitors,
                  .column-impressions
                    max-width: 150px
                  .column-goal-conversion
                    max-width: 230px
                    .table-column-header
                      ::v-deep .reference .title
                        max-width: 126px
                @media screen and (max-width: 1700px)
                  .column-goal-conversion
                    max-width: 190px
                    .table-column-header
                      ::v-deep .reference .title
                        max-width: 180px
                @media screen and (max-width: 1620px)
                  min-width: 800px
                  .column-impressions,
                  .column-chance-to-win
                    max-width: 135px
                  .column-conversion-rate
                    max-width: 100px
                  .column-uplift
                    max-width: 120px
                  .column-goal-conversion
                    max-width: 170px
                    .table-column-header
                      ::v-deep .reference .title
                        max-width: 120px

    &.campaign-conversion
      .domain-content
        &.scrollable-to-the-right
          @media screen and (max-width: 1620px)
            position: relative
            &:after
              content: ""
              position: absolute
              bottom: 0
              right: 0
              height: calc(100% - 200px)
              width: 10px
              background: -webkit-gradient(linear,left top,right top,from(transparent),to(rgba(0,0,0,.04)))
              background: linear-gradient(90deg, transparent, rgba(0,0,0,.04))
              z-index: 99999

        .analytics-table-column
          &-main
            &.scrollable-to-the-left
              @media screen and (max-width: 1620px)
                &:before
                  content: ""
                  position: absolute
                  bottom: 0
                  right: -10px
                  height: calc(100% - 200px)
                  width: 10px
                  background: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.04)),to(transparent))
                  background: linear-gradient(90deg,rgba(0,0,0,.04) 0,transparent)
                  z-index: 99999

          &-secondary
            .thead.scroll,
              .tr
                .th
                  &.column-chance-to-win
                    .table-column-header
                      padding-right: 0rem
            .thead.scroll,
            .tbody.scroll
              @media screen and (max-width: 1620px)
                min-width: 1100px

            @media screen and (max-width: 1920px)
              .column-conversion-rate,
              .column-uplift
                max-width: 100px

              .column-chance-to-win
                max-width: 120px

              .column-assisted-revenue
                min-width: 140px

    &.goal
      .analytics-table-column-main
          &.scrollable-to-the-left
            @media screen and (max-width: 1620px)
              &:before
                content: ""
                position: absolute
                bottom: 0
                right: -10px
                height: calc(100% - 200px)
                width: 10px
                background: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.04)),to(transparent))
                background: linear-gradient(90deg,rgba(0,0,0,.04) 0,transparent)
                z-index: 99999

      .analytics-table-column-secondary
        .thead.scroll,
        .tbody.scroll
          .column-uplift,
          .column-conversion-rate
            max-width: 130px
          .column-chance-to-win
            max-width: 180px
          .column-visitors,
          .column-impressions
            max-width: 250px
          .column-goal-conversion
            max-width: 126px
          @media screen and (max-width: 1919px)
            .column-visitors,
            .column-impressions
              max-width: 150px
          @media screen and (max-width: 1700px)
            .column-goal-conversion
              max-width: 180
              .table-column-header
                ::v-deep .reference .title
                  max-width: 170px
          @media screen and (max-width: 1620px)
            min-width: 800px
            .column-impressions,
            .column-chance-to-win
              max-width: 140px
            .column-conversion-rate
              max-width: 100px
            .column-uplift
              max-width: 100px
            .column-goal-conversion
              max-width: 150px
              .table-column-header
                ::v-deep .reference .title
                  max-width: 140px

      .domain-content
        &.scrollable-to-the-right
          @media screen and (max-width: 1620px)
            position: relative
            &:after
              content: ""
              position: absolute
              bottom: 0
              right: 0
              height: calc(100% - 200px)
              width: 10px
              background: -webkit-gradient(linear,left top,right top,from(transparent),to(rgba(0,0,0,.04)))
              background: linear-gradient(90deg, transparent, rgba(0,0,0,.04))
              z-index: 99999

    &.order-goal
      .domain-content
        &.scrollable-to-the-right
          @media screen and (max-width: 2100px)
            position: relative
            &:after
              content: ""
              position: absolute
              bottom: 0
              right: 0
              height: calc(100% - 200px)
              width: 10px
              background: -webkit-gradient(linear,left top,right top,from(transparent),to(rgba(0,0,0,.04)))
              background: linear-gradient(90deg, transparent, rgba(0,0,0,.04))
              z-index: 99999

        .analytics-table-column
          &-main
            &.scrollable-to-the-left
              @media screen and (max-width: 2100px)
                &:before
                  content: ""
                  position: absolute
                  bottom: 0
                  right: -10px
                  height: calc(100% - 200px)
                  width: 10px
                  background: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.04)),to(transparent))
                  background: linear-gradient(90deg,rgba(0,0,0,.04) 0,transparent)
                  z-index: 99999

          &-secondary
            .thead.scroll,
              .tr
                .th
                  &.column-chance-to-win
                    .table-column-header
                      padding-right: 0rem
            .thead.scroll,
            .tbody.scroll
              @media screen and (max-width: 2100px)
                min-width: 1200px

                .column-conversion-rate,
                .column-uplift
                  max-width: 100px
                .column-chance-to-win
                  max-width: 110px

              .column-goal-conversion
                max-width: 140px
                .table-column-header
                  ::v-deep .reference .title
                    max-width: 120px
            .column-aov
                min-width: 100px

            .column-revenue-uplift,
            .column-impressions,
            .column-chance-to-win,
            .column-total-orders
              min-width: 110px

            .column-revenue
              min-width: 100px

    .column-goal-conversion
      max-width: 180px
      .table-column-header
        ::v-deep .reference .title
          display: block !important
          text-overflow: ellipsis
          overflow: hidden
          max-width: 126px

    ::v-deep .heading-3
      color: $om-dark-grey-3
      margin-right: 80px
      min-width: 255px

    .analytics-tabs
      position: relative
      scrollbar-width: thin
      margin-bottom: 1.5rem
      z-index: 10

      .goal-tabs
        overflow-x: auto
        scrollbar-width: thin
        width: calc(100% - 335px)
        max-width: fit-content
        margin-top: 0.5625rem
        margin-right: 1rem

      .last-updated
        white-space: nowrap
        align-items: start
        font-size: 0.85rem
        margin-left: auto
        color: $om-gray-600
        position: relative
        margin-top: 0.5625rem

        &::before
          content: ""
          position: absolute
          top: -28px
          left: -10px
          height: 65px
          width: 10px
          background: -webkit-gradient(linear,left top,right top,from(transparent),to(rgba(255,255,255,1)))
          background: linear-gradient(90deg, transparent, rgba(255,255,255,1))

    ::v-deep .om-pills
      border-bottom: none
      .om-pills-pill
        min-width: fit-content
        padding-bottom: 4px !important
        &.active
          .body-text
            color: $om-orange
        .body-text
          font-size: 1rem
          color: $om-gray-800
          max-width: 320px
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          font-weight: 500

    .brand-table
      .thead
        .tr
          justify-content: flex-end

          .th
            ::v-deep
              .ds-tooltip > span:first-child
                padding-top: 0 !important
                padding-bottom: 0 !important

      .tbody
        margin-bottom: 0
        .brand-table-tr
          font-size: 14px
          min-height: 55px
          padding: 0
          flex-wrap: nowrap
          justify-content: flex-end
          border-bottom: 1px solid  $om-gray-300

          &.brand-table-tr-variant
            border-bottom: none
            width: 100%
            .td-variant
              padding-left: 10px
              flex: 0 0 125px

            &:hover
              background-color: $om-gray-100

    .hidden
      display: none

    .brand-table .tbody
      .brand-table-tr.hovered
        background-color: $om-gray-100

    .campaign-header
      .table.brand-table:hover,
      &.brand-table-tr-no-variant-column:hover
        background-color: $om-gray-100

      &:hover
        .hidden
          display: block
    .campaign-report-campaign-name
      display: inline-flex
      align-items: center
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-box-orient: vertical
      -webkit-line-clamp: 2
      line-height: 1.5
      max-height: 3rem
      word-break: break-word
    .campaign-settings-link
      color: $om-gray-600
      position: relative
      display: inline-block
      height: 17px
      width: 17px
      z-index: 100

      svg
        height: 1.1rem
        width: 1.1rem

    .table.brand-table.brand-table-td-variant-column-header .campaign-settings-link
      right: 3px

    .table.brand-table,
    .brand-table-td-variant-column-header
      &.hovered
          background-color: $om-gray-100

      svg
        height: 25px
        width: 25px

      .hidden.campaign-settings-link svg
        height: 17px
        width: 17px

    .variant-preview
      ::v-deep .reference
        justify-content: flex-start
        width: 100%

    .closed-up
        display: none !important

    .domain-content
      margin-top: -200px

    .analytics-table-column
      &-main
        padding-top: 200px

        .thead
          .title
            visibility: hidden

        .tbody
          position: relative

          .variant-is-deleted
            color: $om-gray-500

          .campaign-is-deleted
            .variant-is-deleted
              .deleted-text
                display: none

            .campaign-report-campaign-name,
            .variant-name,
            .deleted-text
              color: $om-gray-500

          .tr
            .brand-table-td
              .brand-table-td-inner
                min-height: 52px
            .brand-table-td-variant-column
              padding-left: 0!important
              padding-right: 0
              &-header
                .brand-table-td-inner
                  min-height: 52px
                  display: flex
                  align-items: center

      &-secondary
        overflow-x: auto
        scrollbar-width: thin
        position: relative
        padding-top: 200px

        .thead.scroll
          padding: 0.5rem 0.5rem 0.5rem 0

          .tr
            .th
              &.column-assisted-revenue,
              &.column-revenue-uplift,
              &.column-chance-to-win
                .table-column-header
                  padding-right: 0.75rem

        .tbody
          .tr
            .td
              &.td-variant
                &:last-child
                  .brand-table-td-variant
                    padding-right: 0.75rem
              .brand-table-parent
                padding-right: 0 !important

                .brand-table-td-inner
                  min-height: 52px
                  display: flex
                  flex-direction: column
                  align-items: flex-end
                  justify-content: center
                  flex: 0 0 126px !important
                  &:last-child
                    .table-row-value
                      padding-right: 0.75rem

                  .table-row-value
                    font-weight: 400
                    width: 100%

              .brand-table-child.brand-table-variants
                .brand-table-td.brand-table-td-variant
                  font-weight: 400
                  font-size: 0.75rem
                  width: 100%

                  &.baseline
                    color: #8F97A4

                  &.positive
                    color: $om-alert-green-600
                    font-weight: 700

                  &.negative
                    color: $om-alert-red-500


      &-main,
      &-secondary
        .thead
          background-color: #F7F7F8
          border-top: 1px solid #EBECEE
          border-bottom: 1px solid #EBECEE

          .tr
            background-color: #F7F7F8
            position: relative
            border-bottom: none

            .th
              padding-left: 0.5rem !important
              flex: 0 0 126px !important
              span
                padding: 0
              .table-column-header
                padding: 0
                .title
                  border-bottom: 1px dashed $om-gray-500
                ::v-deep .reference
                  padding: 0
                .title
                  padding: 2px 0
                  color: $om-dark-grey-3
                  font-size: 0.75rem

                ::v-deep .popper > div
                  white-space: break-spaces
</style>
